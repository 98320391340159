<template>
  <!-- 路径【入库管理】>>>【入库单管理】（详情）>>>打印一次性容器 -->
  <div class="printWj">
    <nh-barcode
      v-if="printList.containerCode && codeType=== DICTIONARY.BAR_CODE"
      :value="printList.containerCode"
      :text="printList.containerCode"
      size="big"
    />
    <template v-else-if="printList.containerCode">
      <nh-qrcode
        :text="printList.containerCode"
      />
      <p>{{ printList.containerCode }}</p>
    </template>
  </div>
</template>
<script>
import { DICTIONARY } from '../constant';

export default {
  props: {
    printList: {
      type: Object,
      default: () => ({}),
    },
    codeType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      DICTIONARY,
    };
  },
};
</script>

<style lang="scss">
.printWj {
  page-break-after: always;
  padding: 8px;
}

@media print {
  .print-btn {
    display: none;
  }
}
</style>
