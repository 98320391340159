const DICTIONARY = {
  BAR_CODE: 'nh-barcode',
};
const CODE_TYPE = [{
  value: DICTIONARY.BAR_CODE,
  label: '条码',
}, {
  value: 'nh-qrcode',
  label: '二维码',
}];
export {
  DICTIONARY,
  CODE_TYPE,
};
