<template>
  <div v-loading="loading.init" class="print-page">
    <div class="print-btn">
      <PrintButton
        :type="PRINT_BUTTON.type"
      />
      <div class="margin-24">
        <el-radio-group v-model="form.codeType">
          <el-radio
            v-for="item in CODE_TYPE"
            :key="item.value"
            :label="item.value"
          >
            {{ item.label }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div v-for="(printList, printNumber) in prints" :key="printNumber">
      <component
        :is="printList.component"
        :print-list="printList"
        :code-type="form.codeType"
      />
    </div>
  </div>
</template>

<script>
import loadingMixin from '@thales/loading';

import PrintButton from '../components/PrintButton/PrintButton.vue';
import Normal from './normal/index.vue';
import { PRINT_BUTTON } from '../components/PrintButton/config';

import config from './config';
import { DICTIONARY, CODE_TYPE } from './constant';
import batchInsert from './api';

export default {
  components: {
    PrintButton,
    Normal,
  },
  mixins: [loadingMixin],
  data() {
    return {
      PRINT_BUTTON,
      config,
      DICTIONARY,
      CODE_TYPE,
      prints: [],
      form: {
        codeType: DICTIONARY.BAR_CODE,
      },
      loading: {
        init: false,
      },
    };
  },
  created() {
    const { endCode } = this.$route.query;
    this.init(endCode);
  },
  methods: {
    async init(endCode) {
      try {
        const resp = await batchInsert({
          prefix: '',
          containerType: 'ONCE',
          containerAttr: 'BELONG',
          batchMax: 999999,
          startCode: 1,
          blendedBatch: '是',
          endCode,
        });
        this.prints = resp.map((item) => ({
          ...item,
          component: config.DEFAULT, // 打印样式
        }));
      } catch (error) {
        this.prints = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.print-page {
  max-width: 1123px;
  margin: 0 auto;
}

.print-btn {
  display: flex;
  align-items: baseline;

  .margin-24 {
    margin-left: 24px;
  }
}

@media print {
  .print-btn {
    display: none;
  }

  .afterFooter {
    display: block;
  }
}
 </style>
